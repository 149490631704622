

// src/index.js
import { Auth0Provider } from '@auth0/auth0-react';
import App from './App';
import ReactDOM from 'react-dom';


ReactDOM.render(
  <Auth0Provider
    domain="ronati.eu.auth0.com"
    clientId="J2XUVek0QmyX0BlK9x1EZd5tw1u3vpHV"
    authorizationParams={{
      redirect_uri: window.location.origin,
    }}
  >
    <App />
  </Auth0Provider>,
  document.getElementById('root')
);