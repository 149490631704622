import React, { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

function App() {
  const { isLoading, isAuthenticated, error, user, loginWithRedirect, getAccessTokenSilently } =
    useAuth0();
  const [userMetadata, setUserMetadata] = useState("null");


    const getUserMetadata = async () => {
  
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: `Acquire-Backend-API`,
          },
        })

        setUserMetadata(accessToken)
  
      } catch (e) {
        console.log("e",e);
      }
    };
  

  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  if (isAuthenticated) {
    getUserMetadata();
    return (
      <div>
        <img src={user?.picture} alt={user?.name} />
        <h2>{user?.name}</h2>
        <p>{user?.email}</p>
        <h3>Access Token</h3>
        {userMetadata ? (
          <div>{userMetadata}</div>
        ) : (
          "Could not fetch access token"
        )}
      </div>
    );
  } else {
    return <button onClick={() => loginWithRedirect()}>Log in</button>;
  }
}

export default App;
